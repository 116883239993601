<template>
  <div class="isolate overflow-hidden bg-black">
    <div class="mx-auto max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
      <div class="mx-auto max-w-4xl">
        <p class="mt-2 text-3xl sm:text-5xl font-bold tracking-tight text-white">
          Kickstart your career in cloud development 🚀
        </p>
      </div>
      <div class="relative mt-6">
        <p class="mx-auto max-w-2xl text-lg leading-8 text-white/60">
          The next cohort starts on <span class="text-white font-medium">{{ Constants.START_DATE }}</span>.
          Registration closes on {{ Constants.CLOSE_DATE }}.
        </p>

        <p v-if="isEarlyBird" class="text-white/60 mt-6">
          EARLY BIRD prices (
            <span class="font-bold text-yellow-200 text-xl mx-2">30% OFF</span>
          ) are available until
          <span class="text-white">{{ Constants.EARLY_BIRD_DATE }}</span>
        </p>
        <p v-if="discount" class="text-white/60 mt-6">
          Purchase Power Parity (PPP) discount (
            <span class="font-bold text-yellow-200 text-xl mx-2">{{ discount }}% OFF</span>
          ) will be automatically applied on your order during checkout.
        </p>

        <svg viewBox="0 0 1208 1024" class="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0">
          <ellipse cx="604" cy="512" fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb0)" rx="604" ry="512" />
          <defs>
            <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb0">
              <stop stop-color="#7775D6" />
              <stop offset="1" stop-color="#E935C1" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
    <div class="flow-root bg-white pb-24 sm:pb-32">
      <div class="-mt-80">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
            <div v-for="plan in [singlePaymentPlan, instalmentPlan]" :key="plan.id" class="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10">
              <div>
                <h3 :id="plan.id" class="text-base font-semibold leading-7 text-indigo-600">{{ plan.name }}</h3>
                <div class="mt-4 flex items-baseline gap-x-2">
                  <span v-if="plan.price < plan.priceFull">
                    <span class="text-2xl font-bold tracking-tight text-gray-500 line-through mr-2">${{ plan.priceFull }}</span>
                    <span class="text-5xl font-bold tracking-tight text-gray-900">${{ plan.price }}</span>
                  </span>
                  <span v-if="plan.price >= plan.priceFull" class="text-5xl font-bold tracking-tight text-gray-900">${{ plan.priceFull }}</span>
                  <span v-if="plan.frequency" class="text-base font-semibold leading-7 text-gray-600">{{ plan.frequency }}</span>
                </div>
                <p class="mt-6 text-base sm:text-lg leading-7 text-gray-600">{{ plan.description }}</p>
                <ul role="list" class="mt-10 space-y-4 text-sm sm:text-base leading-6 text-gray-600">
                  <li v-for="feature in plan.features" :key="feature" class="flex gap-x-3">
                    <svg class="h-6 w-5 flex-none text-green-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                      <path fillRule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd" />
                    </svg>

                    {{ feature }}
                  </li>
                </ul>
              </div>
              <button @click="handleClick(plan.id)" target="_blank" :aria-describedby="plan.id" class="mt-8 block rounded-md bg-indigo-600 hover:bg-indigo-500 px-3.5 py-2 text-center text-xl font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Join the cohort
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCookie } from '@/libs/utils'
import { fathomEvent } from '@/libs/analytics'
import * as Constants from '@/libs/constants'

export default {
  data () {
    const sourceUrl = window.location.origin
    const userAgent = navigator.userAgent

    let singlePaymentUrl = `https://theburningmonk.gumroad.com/l/${Constants.SINGLE_PAYMENT_ID}?sourceUrl=${sourceUrl}&userAgent=${userAgent}`
    let installmentUrl = `https://theburningmonk.gumroad.com/l/${Constants.INSTALLMENT_ID}?sourceUrl=${sourceUrl}&userAgent=${userAgent}`

    const browserId = getCookie('_fbp')
    if (browserId) {
      singlePaymentUrl += `&browserId=${browserId}`
      installmentUrl += `&browserId=${browserId}`
    }
    const affiliateId = getCookie('affiliateId')
    if (affiliateId) {
      singlePaymentUrl += `&affiliateId=${affiliateId}`
      installmentUrl += `&affiliateId=${affiliateId}`
    }

    const now = new Date()
    const isEarlyBird = now < Constants.EARLY_BIRD_DEADLINE
    const singlePaymentPrice = isEarlyBird ? Constants.PRICE : Constants.PRICE_FULL
    const instalmentPrice = isEarlyBird ? Constants.INSTALLMENT_PRICE : Constants.INSTALLMENT_PRICE_FULL
    const instalmentTotal = instalmentPrice * Constants.INSTALLMENTS
    const delta = instalmentTotal - singlePaymentPrice

    const singlePaymentPlan = {
      name: 'Single payment',
      id: 'single-payment',
      href: singlePaymentUrl,
      price: singlePaymentPrice,
      priceFull: Constants.PRICE_FULL,
      description: `Save $${delta} with single payment.`,
      features: [
        'Both Serverless Framework and CDK tracks',
        'Life time access to all content',
        'Life time access to private Slack community',
        'Happiness guarantee',
        'Team discount available'
      ]
    }

    const instalmentPlan = {
      name: 'Installment plan',
      id: 'installment-plan',
      href: installmentUrl,
      price: instalmentPrice,
      priceFull: Constants.INSTALLMENT_PRICE_FULL,
      frequency: `/month x ${Constants.INSTALLMENTS} months`,
      description: `Pay in ${Constants.INSTALLMENTS} monthly installments. In total, you will pay $${instalmentTotal}.`,
      features: [
        'Both Serverless Framework and CDK tracks',
        'Life time access to all content',
        'Life time access to private Slack community',
        'Happiness guarantee'
      ]
    }

    return {
      isEarlyBird,
      singlePaymentPlan,
      instalmentPlan,
      plans: [singlePaymentPlan, instalmentPlan],
      Constants,
      discount: null
    }
  },
  methods: {
    handleClick (id) {
      fathomEvent(`clicked-${id}`)

      const plan = this.plans.find(p => p.id === id)
      window.open(plan.href, '_blank')
    },

    handlePppDiscount (amount) {
      this.discount = amount
      const discountedRatio = 1 - amount / 100.0

      this.instalmentPlan.price = Math.round(this.instalmentPlan.price * discountedRatio)
      const instalmentTotal = this.instalmentPlan.price * Constants.INSTALLMENTS
      this.instalmentPlan.description = `Pay in ${Constants.INSTALLMENTS} monthly installments. In total, you will pay $${instalmentTotal}.`

      this.singlePaymentPlan.price = Math.round(this.singlePaymentPlan.price * discountedRatio)
      const delta = instalmentTotal - this.singlePaymentPlan.price
      this.singlePaymentPlan.description = `Save $${delta} with single payment.`
    }
  }
}
</script>
