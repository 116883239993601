<template>
  <div id="curriculum" class="w-full mt-10">
    <h1 class="text-2xl sm:text-4xl font-extrabold text-center">Workshop Curriculum</h1>
    <p class="sm:text-lg italic text-center">Last updated June 2024</p>

    <ul class="mt-5 grid grid-cols-1 lg:grid-cols-2 justify-items-start gap-y-6 lg:gap-x-4">
      <li>
        <p class="md:mt-2 text-xl md:text-3xl font-bold">4-week, self-paced</p>
        <p class="md:text-lg">
          4-week online course, study at your own pace, with weekly live Q&A sessions.
          The lectures and exercises are released every Monday, and you can complete them
          in your own time and get help from me and fellow students on Slack.
          You can also get your burning questions answered at the weekly Q&As.
        </p>
      </li>
      <li>
        <img class="w-[400px] h-[267px]" loading="lazy"
          src="https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,w_400/v1659032854/website/self-paced-format.avif">
      </li>
    </ul>

    <div class="mt-5">
      <CollapsableDropdown title="Week 1: Introductions & REST APIs" :items="week1" />
      <CollapsableDropdown title="Week 2: Testing & CI/CD" :items="week2" />
      <CollapsableDropdown title="Week 3: Event-Driven Architectures & DDD" :items="week3" />
      <CollapsableDropdown title="Week 4: Observability, Cost & Performance" :items="week4" />
    </div>
  </div>
</template>

<script>
import CollapsableDropdown from './CollapsableDropdown.vue'

export default {
  components: {
    CollapsableDropdown
  },
  data () {
    return {
      week1: [
        'Lecture: What do we mean by serverless?',
        'Lecture: When is serverless the right choice for your workload?',
        'Lecture: The serverless-first mindset',
        'Lecture: What about vendor lock-in?',
        'Lecture: AWS Lambda 101',
        'Lecture: Introduction to Lambda EFS integrations',
        'Lecture: Introduction to Lambda Extensions',
        'Lecture: Introduction to Lambda Provisioned Concurrency',
        'Lecture: When to use Lambda Provisioned Concurrency',
        'Lecture: When to use Lambda Destinations',
        "Lecture: Understanding Lambda's scaling behaviour",
        'Lecture: CloudFormation 101',
        'Lecture: AWS IAM 101',
        'Lecture: DynamoDB 101',
        'Lecture: API Gateway 101',
        'Lecture: Serverless Framework vs AWS SAM vs AWS CDK vs Terraform',
        'Exercise: Create a serverless project',
        'Exercise: Invoke Lambda functions locally',
        'Exercise: Working with ephemeral environments',
        'Exercise: Create an API with API Gateway and Lambda',
        'Lecture: Securing API Gateway',
        'Exercise: Secure API Gateway with AWS_IAM',
        'Lecture: Cognito 101',
        'Exercise: Create a new Cognito User Pool',
        'Lecture: Secure API Gateway with Cognito User Pool',
        'Lecture: API Gateway best practices',
        'Lecture: API Gateway REST API vs HTTP API vs ALB',
        'Lecture: When to use Lambda function URLs',
        'Lecture: How to assign a static IP to a Lambda function',
        'Lecture: When to use API Gateway service proxies',
        'Exercise: Optional challenges',
        'Live Instructor Q&As'
      ],
      week2: [
        'Lecture: Serverless requires a different approach to testing',
        'Lecture: The general strategy for testing API Gateway REST APIs',
        'Lecture: How to use remocal tests to achieve fast feedback loops',
        'Exercise: Writing remocal tests',
        'Lecture: Writing end-to-end tests',
        'Exercise: Writing end-to-end tests',
        'Lecture: How to secure CI/CD pipelines',
        'Exercise: Set up CI/CD with GitHub Actions',
        'Lecture: Best practices for AWS Organizations and accounts (NEW!)',
        'Lecture: Why you should use ephemeral environments',
        'Lecture: How to handle serverful resources when using ephemeral environments',
        'Lecture: Introduction to SSM Parameter Store',
        'Lecture: The problems with environment variables',
        'Exercise: Load dynamic configurations',
        'Exercise: Share SSM parameters with temporary environments',
        'Lecture: Securely handle secrets',
        'Exercise: Securely handle secrets',
        'Lecture: SSM Parameter Store vs Secrets Manager',
        'Lecture: How to load test serverless apps',
        'Lecture: How to organize your serverless application into repositories',
        'Exercise: Optional challenges',
        'Live Instructor Q&As'
      ],
      week3: [
        'Lecture: Introduction to Event-Driven Architectures',
        'Lecture: Introduction to Domain Driven Design',
        'Lecture: Introduction to EventBridge',
        'Lecture: Comparing EventBridge event bus topologies',
        'Lecture: Processing events with EventBridge and Lambda',
        'Exercise: Processing events with EventBridge and Lambda',
        'Lecture: How to include SNS and EventBridge in end-to-end tests',
        'Exercise: Include SNS and EventBridge in end-to-end tests',
        'Lecture: Error handling best practices',
        'Exercise: Implement idempotency in event processing',
        'Exercise: Implement error handling in event processing',
        'Lecture: SNS vs SQS vs Kinesis vs EventBridge',
        'Lecture: How to control Lambda concurrency',
        'Lecture: How to structure and version your events',
        'Exercise: Create an envelope for your events (NEW!)',
        'Lecture: Events vs Commands',
        'Lecture: Light event vs. Rich event',
        'Lecture: Domain vs Integration events',
        'Lecture: Context Mapping and Anti-Corruption Layers',
        'Lecture: Where should you implement the Anti-Corruption Layer?',
        'Lecture: How to document event schema for cross-team collaboration (NEW!)',
        'Lecture: Do you need an event broker?',
        'Lecture: How to forward events to a data lake',
        'Lecture: Orchestration vs Choreography',
        'Lecture: Introduction to AWS Step Functions',
        'Lecture: Standard vs Express Workflows',
        'Lecture: Introduction to Amazon State Language (ASL)',
        'Exercise: Implement an order processing workflow with Step Functions',
        'Lecture: How to test Step Functions (NEW!)',
        'Lecture: CQRS, Event Source, Event Streaming, what do they mean?',
        'Exercise: How to archive and replay events (NEW!)',
        'Exercise: Optional challenges',
        'Live Instructor Q&As'
      ],
      week4: [
        'Lecture: Log aggregation',
        'Lecture: Structured logging',
        'Exercise: Structured logging',
        'Lecture: Sample debug logs in production',
        'Exercise: Sample debug logs in production',
        'Exercise: Analyzing and improving Lambda cold starts',
        "Lecture: Understanding Lambda's new LLRT runtime",
        "Lecture: Lambda's advanced logging controls",
        'Lection: How to bypass CloudWatch Logs altogether',
        'Lecture: Introduction to AWS X-Ray',
        'Exercise: X-Ray',
        'Lecture: X-Ray limitations',
        'Lecture: Introduction to Lumigo',
        'Exercise: Lumigo',
        'Lecture: Alerts you should have',
        'Exercise: Right-sizing Lambda memory allocations',
        'Lecture: Costly mistakes to avoid',
        'Lecture: How to build multi-region, active-active serverless applications',
        'Lecture: All you need to know about caching for serverless applications',
        'Live Instructor Q&As'
      ]
    }
  }
}
</script>
