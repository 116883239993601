const PRICE = 770
const PRICE_FULL = 1100
const INSTALLMENT_PRICE = 170
const INSTALLMENT_PRICE_FULL = 240
const INSTALLMENTS = 5
const START_DATE = 'Nov 11th, 2024'
const CLOSE_DATE = 'Nov 17th'
const EARLY_BIRD_DEADLINE = new Date('2024-10-14T00:00:00Z')
const EARLY_BIRD_DATE = 'Oct 14th'
const SINGLE_PAYMENT_ID = 'prsls-nov-2024'
const INSTALLMENT_ID = 'prsls-nov-2024-instalments'
const BE_URL = 'https://ipwkqgl8cg.execute-api.eu-west-1.amazonaws.com/dev'

export {
  PRICE,
  PRICE_FULL,
  INSTALLMENT_PRICE,
  INSTALLMENT_PRICE_FULL,
  INSTALLMENTS,
  START_DATE,
  CLOSE_DATE,
  EARLY_BIRD_DEADLINE,
  EARLY_BIRD_DATE,
  SINGLE_PAYMENT_ID,
  INSTALLMENT_ID,
  BE_URL
}
