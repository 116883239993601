<template>
  <div class="bg-white fixed w-full z-20 top-0 start-0 border-b border-gray-200">
    <div v-if="discount > 0" class="bg-green-400 p-3 text-center font-medium text-md">
      Hey, looks like you qualify for <strong>{{ discount }}%</strong> Parity Purchasing Power discount! It will be automatically applied to your order during checkout :-)
    </div>

    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
      <!-- logo -->
      <RouterLink :to="{ hash: '#home' }" class="flex items-center space-x-3 py-2">
        <img :src="logoUrl" class="h-8" loading="lazy" alt="Production-Ready Serverless Logo">
        <span class="hidden lg:block self-center text-2xl font-semibold whitespace-nowrap text-black">Production-Ready Serverless</span>
      </RouterLink>

      <div class="flex md:order-2 space-x-3 md:space-x-0">
        <a @click="handleEnrolNow()" href="#pricing" class="text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-lg px-4 py-2 text-center">
          Enroll Now
        </a>
        <button @click="handleCollapse()" id="triggerEl" data-collapse-toggle="navbar-sticky" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
          </svg>
        </button>
      </div>

      <!-- navigation -->
      <div id="navbar-sticky" class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1">
        <ul class="flex flex-col p-4 md:p-0 mt-4 text-lg font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 md:flex-row md:mt-0 md:border-0 md:bg-white">
          <li>
            <a href="#curriculum" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">Curriculum</a>
          </li>
          <li>
            <a href="#testimonials" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">Testimonials</a>
          </li>
          <li>
            <a href="#about-me" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">About me</a>
          </li>
          <li>
            <a href="#faq" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">FAQs</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse } from 'flowbite'
import { fathomEvent } from '@/libs/analytics'
import { getDiscount } from '@/libs/ppp'

export default {
  emits: ['ppp-available'],
  data () {
    return {
      discount: null,
      logoUrl: 'https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,h_56/website/prsls-icon.png'
    }
  },
  mounted () {
    const target = document.getElementById('navbar-sticky')
    const trigger = document.getElementById('triggerEl')

    const options = {
    }

    const instanceOptions = {
      id: 'targetEl',
      override: true
    }

    this.collapse = new Collapse(target, trigger, options, instanceOptions)

    getDiscount().then(discount => {
      this.discount = discount
      if (discount > 0) {
        this.$emit('ppp-available', discount)
      }
    })
  },
  methods: {
    handleEnrolNow () {
      fathomEvent('clicked-sticky-enroll-now')
    },
    handleCollapse () {
      this.collapse.toggle()
    }
  }
}
</script>
