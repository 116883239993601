<template>
  <li>
    <div class="flex flex-col">
      <div class="flex place-items-center">
        <div class="h-8 w-8 mr-2 text-blue-500">
          <slot name="icon"></slot>
        </div>
        <p class="text-lg font-bold">{{ feature }}</p>
      </div>
      <slot name="description"></slot>
    </div>
  </li>
</template>

<script>
export default {
  name: 'CohortFeatureComponent',
  props: ['feature']
}
</script>
