<template>
  <div class="flex flex-col">
    <div class="bg-yellow-200 w-full p-2 sm:p-5 pb-5 sm:pb-10 hover:shadow-md">
      <WeeklyProgram :week="week1" />
    </div>
    <div class="bg-blue-200 w-full p-2 sm:p-5 pb-5 sm:pb-10 hover:shadow-md">
      <WeeklyProgram :week="week2" />
    </div>
    <div class="bg-green-200 w-full p-2 sm:p-5 pb-5 sm:pb-10 hover:shadow-md">
      <WeeklyProgram :week="week3" />
    </div>
    <div class="bg-orange-200 w-full p-2 sm:p-5 pb-5 sm:pb-10 hover:shadow-md">
      <WeeklyProgram :week="week4" />
    </div>
  </div>
</template>

<script>
import WeeklyProgram from './WeeklyProgram.vue'

export default {
  components: {
    WeeklyProgram
  },
  data () {
    return {
      week1: {
        name: 'Week 1: Introductions & REST APIs',
        color: 'yellow',
        themes: [
          {
            id: 'intro-to-aws-and-serverless',
            name: 'Intro to AWS & serverless',
            img: 'logo-week1-aws.png',
            lessons: [
              { name: 'Understand what we mean by "serverless" when it\'s the right choice for your workload.' },
              { name: 'Introduction to popular serverless services on AWS, including Lambda, DynamoDB, Cognito, API Gateway and Step Functions.' },
              { name: 'Understand how AWS Lambda works under the hood - how it handles synchronous vs. asynchronous invocations and how Lambda scales based on throughput.' },
              { name: 'Understand when to use advanced features such as EFS integrations, provisioned concurrency and extensions.' }
            ]
          },
          {
            id: 'intro-to-iac',
            name: 'Infrastructure-as-Code (IaC)',
            img: 'logo-week1-iac.png',
            lessons: [
              { name: 'Introduction to the concept of infrastructure-as-code and how CloudFormation works.' },
              { name: 'CloudFormation is one of the most important services on AWS. But it\'s also rather verbose! So instead of writing CloudFormation by hand, we will be using CDK and the Serverless Framework in the workshop.' },
              { name: 'All the exercises are available in both CDK and Serverless Framework.' }
            ]
          },
          {
            id: 'apigw-lambda',
            name: 'Building APIs with API Gateway & Lambda',
            img: 'logo-week1-apigw-lambda.png',
            lessons: [
              { name: 'Understand how API Gateway works, and how to build REST APIs with API Gateway and Lambda.' },
              { name: 'We will share some API Gateway best practices, and compare API Gateway REST APIs vs. HTTP APIs vs. ALB to help you understand when to use which.' },
              { name: 'But you don\'t have to use API Gateway nowadays, since Lambda has Function URLs. We will also explain how Function URL works and when to use it instead of API Gateway.' }
            ]
          },
          {
            id: 'apigw-auth',
            name: 'Authentication & Authorization',
            img: 'logo-week1-apigw-auth.png',
            lessons: [
              { name: 'Understand the different authentication and authorization options you have with API Gateway and when to use which.' },
              { name: 'In the exercises, we will show you how to create Cognito User Pools and how to secure API endpoints with AWS_IAM and Cognito authorizers.' }
            ]
          }
        ]
      },
      week2: {
        name: 'Week 2: Testing & CI/CD',
        color: 'blue',
        themes: [
          {
            id: 'testing-sls',
            name: 'Testing Serverless Architectures',
            img: 'logo-week2-test-honeycomb.png',
            lessons: [
              { name: 'We will explain why serverless architectures require a different approach to testing, because they have a different risk profile to applications running on containers or EC2 instances.' },
              { name: 'I will explain my general strategy for testing API Gateway REST APIs.' },
              { name: 'In the exercises, you will see how to write "remocal" tests that combine the best of local and remote testing. Giving you the fast feedback loop of local tests with the accuracy of testing against the real AWS services instead of mocks.' }
            ]
          },
          {
            id: 'gh-actions',
            name: 'CI/CD pipeline with GitHub Actions',
            img: 'logo-week2-gh-actions.png',
            lessons: [
              { name: 'Understand how to set up CI/CD pipelines with GitHub Actions, using IAM federation so you no longer need a CI/CD user.' },
              { name: 'If you are stuck with a CI/CD tool that doesn\'t support IAM federation then I will show you an approach for securing your IAM user.' }
            ]
          },
          {
            id: 'ephemeral-envs',
            name: 'Ephemeral Environments',
            img: 'logo-week2-temp-envs.png',
            lessons: [
              { name: 'The use of ephemeral environments is easily the most impactful practice that has co-evolved with serverless technologies.' },
              { name: 'I will explain what it is, how it works and help you put into practice using ephemeral environments in your development workflow.' },
              { name: 'I will also show you how to deal with serverful resources (i.e. resources where you have to pay for uptime for, such as RDS and OpenSearch) and how to share SSM parameters between ephemeral environments.' }
            ]
          },
          {
            id: 'config-management',
            name: 'Config & Secret Management',
            img: 'logo-week2-config.png',
            lessons: [
              { name: 'Introduction to SSM Parameter Store and Secrets Manager and understand how they differ and when to use which.' },
              { name: 'I will explain the problems with environment variables and best practices for handling secrets.' }
            ]
          }
        ]
      },
      week3: {
        name: 'Week 3: Event-Driven Architectures & DDD',
        color: 'green',
        themes: [
          {
            id: 'evb-lambda',
            name: 'Intro to Event-Driven Architectures',
            img: 'logo-week3-evb-lambda.png',
            lessons: [
              { name: 'Introduction to EventBridge, and how to process events in real-time using Lambda and EventBridge.' },
              { name: 'Event-driven architecture is a great way to scale a system by keeping services loosely coupled through shared events.' },
              { name: 'I will explain how to organize your system into separate services and repos, and some best practices for building event-driven architectures - such as how to structure your events, and how to archive and replay them.' },
              { name: 'We will also look at different event bus topologies and how to choose the best one for your use case.' }
            ]
          },
          {
            id: 'testing-eda',
            name: 'Testing Event-Driven Architectures',
            img: 'logo-week3-test-eda.png',
            lessons: [
              { name: 'One of the biggest challenges with building event-driven architectures is that testing becomes more difficult.' },
              { name: 'I will show you my general strategy for testing event-driven architectures and how to include SNS topics and EventBridge buses in our remocal and end-to-end tests. The same approach can be applied to other asynchronous event sources too.' }
            ]
          },
          {
            id: 'compare-messaging-services',
            name: 'Choosing the Right Messaging Service',
            img: 'logo-week3-msg-services.png',
            lessons: [
              { name: 'AWS offers many messaging services - SNS, SQS, EventBridge, Kinesis, IoT Core, just to name a few!' },
              { name: 'Knowing when to use which can be challenging, and the decision can have long-lasting impact on your system and your team.' },
              { name: 'I will give you some guidelines for choosing the right messaging service for your workload and show you some important considerations you need to make.' },
              { name: 'Additionally, I will explain when NOT to use events and to prefer an orchestration approach instead.' }
            ]
          },
          {
            id: 'error-handling',
            name: 'Error Handling Best Practices',
            img: 'logo-week3-error-handling.png',
            lessons: [
              { name: 'Another common challenge with event-driven architectures is error handling and dealing with idempotency.' },
              { name: 'I will show you best practices for handling error when processing asynchronous events with Lambda.' },
              { name: 'And you will see how to use the AWS Lambda PowerTools to implement idempotency, to ensure the same event is not processed twice (even though async Lambda invocations are inherently at-least-once).' },
              { name: 'We will also talk about handling partial failures, which is an important consideration when working with batch event sources (e.g. SQS and Kinesis) and closely relate to idempotency.' }
            ]
          }
        ]
      },
      week4: {
        name: 'Week 4: Observability, Cost & Performance',
        color: 'orange',
        themes: [
          {
            id: 'lambda-logging',
            name: 'Lambda Logging Best Practices',
            img: 'logo-week4-logging.png',
            lessons: [
              { name: 'Understand why structured logging is important, and why you should sample debug logs in production to minimize the cost of CloudWatch Logs.' },
              { name: 'We will discuss Lambda\'s advanced logging controls and how they work.' },
              { name: 'And I will show you some tips for keeping CloudWatch Logs cost under control, and how to bypass CloudWatch Logs altogether if you\'re using a 3rd-party log aggregation service already.' }
            ]
          },
          {
            id: 'xray-tracing',
            name: 'Intro to Distributed Tracing & X-Ray',
            img: 'logo-week4-tracing-v2.png',
            lessons: [
              { name: 'I will show you how to integrate with X-Ray and how to instrument your application to get the most out of X-Ray.' },
              { name: 'But at the end of the day, X-Ray is still a rather limited service... I will show you its limitations and why I prefer to use Lumigo in my projects instead.' },
              { name: 'Of course, I will give you a demo of Lumigo and how to use its no-code instrumentation to make debugging easier.' }
            ]
          },
          {
            id: 'lambda-alerting',
            name: 'Monitoring & Alerting Best Practices',
            img: 'logo-week4-alarm.png',
            lessons: [
              { name: 'Understand why, and how to record custom application metrics using the Embedded Metric Format.' },
              { name: 'Understand why you shouldn\'t use averages, and should prefer tail latencies instead.' },
              { name: 'And I will show you what alerts you should have in your serverless application.' }
            ]
          },
          {
            id: 'cost-optimizations',
            name: 'Performance and Cost Optimizations',
            img: 'logo-week4-cost.png',
            lessons: [
              { name: 'I will show you how to analyze and improve Lambda cold starts, and take a look at Lambda\'s new LLRT runtime.' },
              { name: 'I will explain how to right-size the memory setting for your Lambda functions, so you get the right balance between power and cost.' },
              { name: 'And I will show you how to model and project the cost of your application and some costly mistakes you need to watch out for!' }
            ]
          }
        ]
      }
    }
  }
}
</script>
